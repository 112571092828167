<template>
  <div>
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
            </div>
            <div class="view-options d-flex" />
          </div>
        </div>
      </div>
    </section>

    <b-row
      v-if="playerId"
    >
      <b-col>
        <b-card>
          <h4 class="mb-0">
            {{ playerName }}
          </h4>
        </b-card>
      </b-col>
    </b-row>

    <div v-if="!loading">
      <b-tabs
        v-model="currentTab"
        content-class="pt-1"
        fill
      >
        <b-tab
          v-for="t in tabs"
          :key="t.title"
          :title="$t(t.title)"
          lazy
        >
          <b-card>
            <v-select
              v-model="currentGraph"
              :clearable="false"
              label="title"
              :options="graphOptions[currentTab]"
            />
          </b-card>
          <div v-if="!loading">
            <b-card
              no-body
              class="card-revenue-budget"
            >
              <b-row class="mx-0">
                <b-col
                  v-if="strokeGain[0].data.length > 0"
                  md="12"
                  class="revenue-report-wrapper"
                >
                  <div class="d-sm-flex justify-content-between align-items-center mb-3">
                    <h4 class="card-title mb-50 mb-sm-0">
                      {{ currentGraph.title }}
                    </h4>
                  </div>

                  <!-- chart -->
                  <vue-apex-charts
                    id="strokegains-chart"
                    type="bar"
                    height="600"
                    :options="chartOptions"
                    :series="strokeGain"
                  />
                </b-col>
                <b-col
                  v-else
                >
                  <b-card>
                    {{ $t('No result available') }}
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div v-else>
      <loading />
    </div>

    <portal to="content-renderer-sidebar-detached-left">
      <left-filter-dashboard
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        :title="currentGraph.title"
        :key1="tabs[currentTab].key"
        :key2="currentGraph.key"
        @update="update"
      />
    </portal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, BRow, BCol, BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import LeftFilterDashboard from '../../components/LeftFilterDashboard.vue'
import configuration from '../stats/config'
import Loading from '../../components/Loading.vue'

export default {
  components: {
    VueApexCharts,
    BCard,
    vSelect,
    BRow,
    BCol,
    BTabs,
    BTab,
    LeftFilterDashboard,
    Loading,
  },
  directives: {
    Ripple,
  },
  props: {
    playerId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      playerName: '',
      currentGraph: {},
      graphOptions: [
        configuration.strokesGained[0].tables.filter(t => t.units === 'both' || t.units === this.$store.state.spider.units),
        configuration.strokesGained[1].tables.filter(t => t.units === 'both' || t.units === this.$store.state.spider.units),
        configuration.strokesGained[2].tables.filter(t => t.units === 'both' || t.units === this.$store.state.spider.units),
      ],
      currentTab: 0,
      loadingLocal: true,
      filters: {
        greenspeed: -1,
        rain: -1,
        wind: -1,
        temperature: null, // new
        holesNumber: 18, // played not 0
        qualification: -1,
        competition: -1,
        shotByShot: 1,
        roundType: null,
        selectedPlayers: [],
        selectedGroup: null,
        startDate: '',
        endDate: '',
      },
    }
  },
  computed: {
    filterOptions() {
      return {
        greenspeed: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: this.$t('Slow'),
            value: 0,
          },
          {
            text: this.$t('Med'),
            value: 1,
          },
          {
            text: this.$t('Fast'),
            value: 2,
          },
        ],
        rain: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: this.$t('No Rain'),
            value: 0,
          },
          {
            text: this.$t('Rain'),
            value: 1,
          },
        ],
        wind: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: this.$t('Low'),
            value: 0,
          },
          {
            text: this.$t('Med'),
            value: 1,
          },
          {
            text: this.$t('Strong'),
            value: 2,
          },
        ],
        temperature: [
          {
            text: this.$t('All'),
            value: null,
          },
          {
            text: this.$t('Normal'),
            value: 0,
          },
          {
            text: this.$t('Hot'),
            value: 1,
          },
          {
            text: this.$t('Cold'),
            value: -1,
          },
        ],
        holesNumber: [
          {
            text: this.$t('9 Holes'),
            value: 9,
          },
          {
            text: this.$t('18 Holes'),
            value: 18,
          },
        ],
        qualification: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: this.$t('Normal'),
            value: 1,
          },
          {
            text: this.$t('Qualification'),
            value: 3,
          },
          {
            text: this.$t('Competition'),
            value: 2,
          },
        ],
        competition: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: '1',
            value: 1,
          },
          {
            text: '2',
            value: 2,
          },
          {
            text: '3',
            value: 3,
          },
          {
            text: '4',
            value: 4,
          },
        ],
        shotByShot: [
          {
            text: this.$t('Flash'),
            value: 0,
          },
          {
            text: this.$t('Full'),
            value: 1,
          },
        ],
        roundType: [
          {
            text: this.$t('All'),
            value: null,
          },
          {
            text: this.$t('Worst 5'),
            value: 'worst_5',
          },
          {
            text: this.$t('Best 5'),
            value: 'best_5',
          },
          {
            text: this.$t('Last 1'),
            value: 'last_1',
          },
          {
            text: this.$t('Last 3'),
            value: 'last_3',
          },
          {
            text: this.$t('Last 5'),
            value: 'last_5',
          },
          {
            text: this.$t('Last 10'),
            value: 'last_10',
          },
          {
            text: this.$t('Last 15'),
            value: 'last_15',
          },
          {
            text: this.$t('Last 20'),
            value: 'last_20',
          },
        ],
      }
    },
    tabs() {
      return [
        {
          title: this.$t('Categories'),
          key: 'strokesGained',
        },
        {
          title: this.$t('Putting Distances'),
          key: 'strokesGained',
        },
        {
          title: this.$t('Shot Distances'),
          key: 'strokesGained',
        },
      ]
    },
    loading() {
      return this.$store.state.spider.loadingData || this.loadingLocal
    },
    strokeGain() {
      return this.$store.state.spider.strokeGain
    },
    chartOptions() {
      if (this.$store.state.spider.playerStats === null) {
        return {}
      }
      return {
        chart: {
          stacked: true,
          type: 'bar',
          toolbar: {
            show: true,
            tools: {
              zoomin: true,
              zoomout: true,
              reset: true,
              download: false,
              selection: true,
              zoom: true,
              pan: false,
            },
          },
        },
        grid: {
          padding: {
            top: -20,
            bottom: -10,
          },
          yaxis: {
            lines: { show: false },
          },
        },
        tooltip: {
          custom({
            seriesIndex, dataPointIndex, w,
          }) { // series[seriesIndex][dataPointIndex]
            const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]
            return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${data.course}</div>`
            + '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">'
            // + '<span class="apexcharts-tooltip-marker" style="background-color: '+$themeColors.danger+';"></span>'
                + '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">'
                  + '<div class="apexcharts-tooltip-y-group" style="font-weight: bold;">'
                    + `<span class="apexcharts-tooltip-marker" style="background-color: ${data.y > 0 ? $themeColors.primary : $themeColors.danger};display: inline-block; "></span>`
                    + `<span class="apexcharts-tooltip-text-label">${data.title}: </span>`
                    + `<span class="apexcharts-tooltip-text-value">${data.y}</span>`
                    + '</div>'
                  + '<div class="apexcharts-tooltip-y-group" style="font-weight: bold"><span class="apexcharts-tooltip-text-label">FW: </span>'
                    + `<span class="apexcharts-tooltip-text-value">${data.fw}%</span>`
                    + '</div>'
                  + '<div class="apexcharts-tooltip-y-group" style="font-weight: bold"><span class="apexcharts-tooltip-text-label">GIR: </span>'
                    + `<span class="apexcharts-tooltip-text-value">${data.gir}%</span>`
                    + '</div>'
                  + '<div class="apexcharts-tooltip-y-group" style="font-weight: bold"><span class="apexcharts-tooltip-text-label">PUTTS: </span>'
                    + `<span class="apexcharts-tooltip-text-value">${data.putts}</span>`
                    + '</div>'
                  + '</div>'
          },
        },
        xaxis: {
          // type: 'numeric',
          tickAmount: this.strokeGain[0].data.length > 15 ? 15 : 'dataPoints',
          tickPlacement: 'on',
          hideOverlappingLabels: true,
          labels: {
            minHeight: 50,
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: [$themeColors.primary, $themeColors.danger],
        plotOptions: {
          bar: {
            columnWidth: '17%',
            endingShape: 'rounded',
            colors: {
              ranges: [{
                from: 0,
                to: 100,
                color: $themeColors.primary,
              }, {
                from: -100,
                to: 0,
                color: $themeColors.danger,
              }],
              columnWidth: '100%',
            },
          },
          distributed: true,
        },
        yaxis: {
          labels: {
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
            formatter(y) { return y.toFixed(3) },
          },
        },
      }
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(newVal) {
        this.$store.commit('spider/filterData', newVal)
        this.$store.commit('spider/updateStatistics')
        this.$store.commit('spider/updateplayerStats', this.playerId ? +this.playerId : getUserData().user_id)
        this.$store.commit('spider/updateGraph', { title: this.$t(this.currentGraph.title), key1: this.tabs[this.currentTab].key, key2: this.currentGraph.key })
      },
    },
    currentTab(newVal) {
      [this.currentGraph] = this.graphOptions[newVal]
    },
    currentGraph(newVal) {
      if (this.$store.state.spider.playerStats === null) {
        return
      }
      this.loadingLocal = true
      this.$store.commit('spider/updateGraph', { title: this.$t(newVal.title), key1: this.tabs[this.currentTab].key, key2: newVal.key })
      this.loadingLocal = false
    },
  },
  beforeCreate() {
    this.$store.dispatch('spider/updatePlayers')
  },
  created() {
    [[this.currentGraph]] = this.graphOptions
    this.$store.dispatch('spider/updateStats', { filters: this.filters, trends: null }).then(() => {
      this.$store.commit('spider/filterData', this.filters)
      this.$store.commit('spider/updateplayerStats', this.playerId ? +this.playerId : getUserData().user_id)
      this.$store.commit('spider/updateGraph', { title: this.$t('Total'), key1: 'strokesGained', key2: 'strokesGainedTotal' })
      this.loadingLocal = false
      // Set playerName after receiving players from API
      const u = getUserData()
      this.playerName = this.playerId ? this.$store.state.spider.players.find(item => item.id === this.playerId).full_name : `${u.firstname} ${u.name}`
    })
      .catch(() => {
        this.loadingLocal = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Error'),
            icon: 'SlashIcon',
            variant: 'danger',
            text: this.$t('We got an error trying to get your data, please try again'),
          },
        })
      })
  },
  methods: {
    update() {
      this.$store.commit('spider/filterData', this.filters)
      this.$store.commit('spider/updateStatistics')
      this.$store.commit('spider/updateplayerStats', this.playerId ? +this.playerId : getUserData().user_id)
      this.$store.commit('spider/updateGraph', { title: this.$t(this.currentGraph.title), key1: this.tabs[this.currentTab].key, key2: this.currentGraph.key })
    },
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
